import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

type POSITION = 'top' | 'right' | 'bottom' | 'left';

export const Sheet = ({
  open,
  setOpen,
  children,
  onOpen,
  onClose,
  position = 'right',
  className,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  position?: POSITION;
  className?: string;
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const getTransform = useCallback(() => {
    if (open) {
      if (position === 'right') {
        return 'translateX(0) translateY(-50%)';
      }
      if (position === 'left') {
        return 'translateX(0) translateY(-50%)';
      }
      if (position === 'top') {
        return 'translateX(-50%) translateY(0)';
      }
      if (position === 'bottom') {
        return 'translateX(-50%) translateY(0)';
      }
      return 'translateX(0) translateY(-50%)';
    } else {
      if (position === 'right') {
        return 'translateX(100%) translateY(-50%)';
      }
      if (position === 'left') {
        return 'translateX(-100%) translateY(-50%)';
      }
      if (position === 'top') {
        return 'translateX(-50%) translateY(-100%)';
      }
      if (position === 'bottom') {
        return 'translateX(-50%) translateY(100%)';
      }
      return 'translateX(100%) translateY(-50%)';
    }
  }, [open, position]);

  const initialTransform = useCallback(() => {
    if (position === 'right') {
      return 'top-[50%] right-0 translate-y-[-50%] translate-x-full';
    }
    if (position === 'left') {
      return 'top-[50%] left-0 translate-y-[-50%] -translate-x-full';
    }
    if (position === 'top') {
      return 'left-[50%] top-0 translate-x-[-50%] translate-y-full';
    }
    if (position === 'bottom') {
      return 'left-[50%] bottom-0 translate-x-[-50%] -translate-y-full';
    }
    return 'top-[50%] translate-y-[-50%]';
  }, [position]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      requestAnimationFrame(() => {
        if (backdropRef.current && containerRef.current) {
          backdropRef.current.style.opacity = '0.5';
          containerRef.current.style.transition = 'transform 0.5s';
          containerRef.current.style.transform = getTransform();
          onOpen?.();
        }
      });
    } else {
      document.body.style.overflow = 'unset';
      requestAnimationFrame(() => {
        if (backdropRef.current && containerRef.current) {
          backdropRef.current.style.opacity = '0';
          containerRef.current.style.transition = 'transform 0.15s';
          containerRef.current.style.transform = getTransform();
          onClose?.();
        }
      });
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <>
      <div
        ref={backdropRef}
        className={classNames(
          'caregiver-sheet-backdrop fixed inset-0 bg-black opacity-0 z-50',
          open ? 'flex' : 'hidden'
        )}
        style={{
          transition: 'opacity 0.15s',
        }}
        onClick={() => setOpen(false)}
      ></div>
      <div
        ref={containerRef}
        className={classNames(
          'caregiver-sheet-container fixed',
          'flex justify-center items-center z-50',
          initialTransform(),
          className
        )}
        style={{
          transition: 'transform 0.5s',
          animationTimingFunction: 'cubic-bezier(.4,0,.2,1)',
          transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
        }}
      >
        {children}
      </div>
    </>
  );
};
