import React from 'react';
import classNames from 'classnames';
export const Board = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames('board px-5 bg-white', className)}>
      {children}
    </div>
  );
};
