import mixpanel, { Dict } from 'mixpanel-browser'

export class MixpanelEventLogger {
  private static instance: MixpanelEventLogger

  private constructor() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string, {
      debug: process.env.VERCEL_ENV !== 'production',
    })
  }

  static getInstance() {
    if (!MixpanelEventLogger.instance) {
      MixpanelEventLogger.instance = new MixpanelEventLogger()
    }
    return MixpanelEventLogger.instance
  }

  getUserId() {
    return mixpanel.get_distinct_id()
  }

  setUserId(userId: string | undefined) {
    mixpanel.identify(userId)
  }

  setUserProperties(properties: object | null) {
    if (properties && typeof properties === 'object') {
      mixpanel.register(properties)
    }
  }

  track(name: string, properties?: Dict, optionsOrCallback?: any) {
    mixpanel.track(name, properties, optionsOrCallback)
  }

  trackRevenue(_revenuProps: any) {
    console.warn('MixpanelEventLogger.trackRevenue is not implemented')
  }

  reset() {
    mixpanel.reset()
  }
}
