/* eslint-disable react-hooks/exhaustive-deps */
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { RecoilRoot } from 'recoil'
import { QueryClientProvider } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/query-core'
import { DefaultSeo } from 'next-seo'
import { useEffect, useState } from 'react'
import { useEventLogging } from '@packages/jslib'
import { BexProvider } from '@packages/bex'

import { getUser } from '@lib/query/queryFunction'
import useAuth from '@hooks/useAuth'
import { BexSwitcherContainer } from '@components/BexSwitcher/BexSwitcherContainer'

import SEO from '../nextseo.config'

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 5400000,
          },
        },
      })
  )

  const { setUserId, setUserProperties } = useEventLogging()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    const initializeUserAmplitude = async () => {
      const user = await getUser()()
      if (user?.id) setUserId({ userId: user.id })
      if (user?.status) setUserProperties({ properties: { sign_up_status: user.status } })
    }
    if (isAuthenticated) initializeUserAmplitude()
  }, [isAuthenticated])

  return (
    <BexProvider experimentFetchMode="onDemand">
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <DefaultSeo {...SEO} />
          <Component {...pageProps} />
          <BexSwitcherContainer />
        </QueryClientProvider>
      </RecoilRoot>
    </BexProvider>
  )
}
