import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

declare global {
  interface Window {
    adsbygoogle: any[]
  }
}

interface AdSenseProps {
  className?: string
  slot: string
  adFormat?: string

  fixed?: boolean
  placeholderClassName?: string
  fixedClassName?: string
}

const GOOGLE_ADSENSE_CLIENT_ID = 'ca-pub-3193576047545730'

const AdSense = ({
  className,
  slot,
  adFormat,
  fixed,
  fixedClassName,
  placeholderClassName,
}: AdSenseProps) => {
  const advertRef = useRef<HTMLDivElement>(null)
  const isInitialized = useRef(false)
  const isDevelopment = process.env.VERCEL_ENV !== 'production'
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      },
      {
        threshold: 0.1, // 10% 노출 시 감지
      }
    )

    if (advertRef.current) {
      observer.observe(advertRef.current)
    }

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    // Development 환경에서는 실행하지 않음
    if (isDevelopment || !isVisible) return

    // Script 로드 확인
    const loadAdSenseScript = () => {
      if (!document.querySelector(`script[src*="adsbygoogle"]`)) {
        const script = document.createElement('script')
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${GOOGLE_ADSENSE_CLIENT_ID}`
        script.async = true
        script.crossOrigin = 'anonymous'
        document.head.appendChild(script)
      }
    }

    // AdSense 초기화
    const pushAd = () => {
      if (isInitialized.current) return

      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
        isInitialized.current = true
      } catch (e) {
        console.error('AdSense initialization error:', e)
      }
    }

    loadAdSenseScript()

    const interval = setInterval(() => {
      if (window.adsbygoogle) {
        pushAd()
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
      isInitialized.current = false
    }
  }, [isDevelopment, isVisible])

  if (!isVisible) {
    return <div ref={advertRef} className={classNames('min-h-[100px] w-full', className)} />
  }

  if (isDevelopment) {
    return (
      <div
        className={classNames(
          'flex items-center justify-center',
          'text-caption-1 text-gray-60',
          'bg-gray-100 border border-gray-60 border-dashed',
          'min-h-[100px] w-full',
          placeholderClassName
        )}
      >
        광고 영역 (AdSense)
      </div>
    )
  }

  if (fixed) {
    return (
      <div ref={advertRef} className={classNames('min-h-[100px] w-full', className)}>
        <ins
          className={classNames(
            'adsbygoogle inline-block w-[360px] h-[300px] mx-auto',
            fixedClassName
          )}
          style={{ display: 'block' }}
          data-ad-client={GOOGLE_ADSENSE_CLIENT_ID}
          data-ad-slot={slot}
        />
      </div>
    )
  }

  return (
    <div ref={advertRef} className={classNames('min-h-[50px] w-full', className)}>
      <ins
        className="adsbygoogle block w-full"
        style={{ display: 'block' }}
        data-ad-client={GOOGLE_ADSENSE_CLIENT_ID}
        data-ad-slot={slot}
        data-ad-format={adFormat ?? 'auto'}
        data-full-width-responsive="true"
      />
    </div>
  )
}

export default AdSense
