import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { MdClose, MdOutlineNavigateBefore } from 'react-icons/md';

import ModalPortal from './Portal';
import { IconButton } from '../IconButton';

export interface ModalProps {
  full?: boolean;
  backdrop?: boolean;
  closeOnBackdropClicked?: boolean;
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  title?: string | React.ReactNode;
  footer?: React.ReactNode;
  showX?: boolean;
  backButtonLabel?: string;
  children?: React.ReactNode;
  onClose?: (result?: any) => void;
  className?: string;
  backdropClassName?: string;
  modalLayoutClassName?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  contentLayoutClassName?: string;
  zIndex?: 'z-50' | 'z-40' | 'z-30' | 'z-[1000]';
  overChildrenComponent?: React.ReactNode;
  modalPosition?: 'center' | 'bottom' | 'top' | 'floor';
  rounded?: boolean;
  footerClassName?: string;
  preventCloseCallback?: () => void;
}

export interface ModalType extends React.FC<ModalProps> {
  Portal: typeof ModalPortal;
}

export const Modal: ModalType = ({
  full,
  backdrop = true,
  closeOnBackdropClicked = true,
  open,
  setOpen,
  title,
  footer,
  showX = true,
  backButtonLabel,
  children,
  className,
  backdropClassName,
  modalLayoutClassName,
  wrapperClassName,
  titleClassName,
  contentLayoutClassName,
  zIndex,
  onClose,
  overChildrenComponent,
  modalPosition,
  rounded,
  footerClassName,
  preventCloseCallback,
}) => {
  const handleClose = () => {
    if (preventCloseCallback) {
      preventCloseCallback();
      return;
    }

    setOpen?.(false);
    onClose?.();
  };

  const rootStyle = `relative ${zIndex ?? 'z-50'}`;

  const childComponetFixedPosition = () => {
    switch (modalPosition ?? 'center') {
      case 'center':
        return 'justify-center top-0';
      case 'bottom':
        return 'justify-end bottom-0 pb-10';
      case 'floor':
        return 'justify-end bottom-0';
      case 'top':
        return 'justify-top top-10';
    }
  };

  const childComponentStyle = `fixed flex flex-col ${childComponetFixedPosition()} items-center left-0 w-full !h-full`;

  if (!open) {
    return null;
  }

  return (
    <div className={classNames(rootStyle, open ? '' : 'hidden')}>
      {backdrop && (
        <div
          className={classNames(
            'fixed inset-0',
            backdrop ? 'bg-black/70' : '',
            backdropClassName
          )}
          aria-hidden
        />
      )}

      <div
        onClick={!full && closeOnBackdropClicked ? handleClose : undefined}
        className={classNames(
          'modal-content',
          childComponentStyle,
          {
            '!w-[100vw] !h-[100vh] bg-white': full,
            '!justify-start': full,
          },
          modalLayoutClassName
        )}
      >
        <div
          className={classNames(
            'w-full flex flex-col justify-center items-center overflow-y-auto',
            full ? 'max-w-none bg-white' : 'max-w-xl px-4',
            wrapperClassName
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex w-full items-center justify-center">
            {overChildrenComponent}
          </div>
          <div
            className={classNames(
              'mx-auto max-w-xl w-full bg-white rounded-2xl box-border overflow-y-hidden',
              contentLayoutClassName,
              {
                '!overflow-y-auto': full,
                '!rounded-none': full,
                '!px-0': full,
                '!my-0': full,
                // '!h-[100vh]': full,
                // '!max-h-[100vh]': full,
                '!rounded-t-2xl': rounded,
              }
            )}
          >
            {backButtonLabel && (
              <div className="px-[-40px]">
                <div
                  onClick={handleClose}
                  className={
                    'flex text-left cursor-pointer text-black my-auto px-5 py-4 text-label-1'
                  }
                >
                  <MdOutlineNavigateBefore size={30} />
                  <span className="my-auto">{backButtonLabel}</span>
                </div>
                <div className="w-full border border-border-10" />
              </div>
            )}
            {showX && (
              <IconButton
                size={'medium'}
                className="absolute top-0 right-0"
                onClick={handleClose}
                icon={MdClose}
              />
            )}
            <div
              className={classNames(
                'text-title-3',
                {
                  'mt-10': showX,
                },
                titleClassName || ''
              )}
            >
              {title}
            </div>
            <div
              className={classNames('overflow-y-auto', className, {
                'pb-40': !!footer && full,
              })}
            >
              {children}
            </div>
            {footer && (
              <section
                className={classNames(
                  full
                    ? 'bg-white fixed bottom-0 left-0 border-t border-border-10 flex justify-center w-full'
                    : ''
                )}
              >
                <div
                  className={classNames('p-4 w-full max-w-xl', footerClassName)}
                >
                  {footer}
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.Portal = ModalPortal;
