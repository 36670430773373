import { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { Sheet } from '@packages/bds'

import { fetchAllExperimentsForUser, forcedAssignment } from '../core/fetch'
import { useBexState } from '../core/useBexState'

const BexSWitcherUi = ({
  unitId,
  onSwitch,
}: {
  unitId: string
  onSwitch: ({
    experimentKey,
    treatmentKey,
  }: {
    experimentKey: string
    treatmentKey: string
  }) => void
}) => {
  const [data, setData] = useState<any[]>([])
  const [showExperiments, setShowExperiments] = useState(false)
  const draggedRef = useRef<boolean>(false)

  const fetchExperiments = async (unitId: string) => {
    const res = await fetchAllExperimentsForUser(unitId)
    if (res.data?.length > 0) {
      const data = res.data.map((experiment: any) => {
        const unit = experiment.unit ?? {}
        return {
          id: experiment.id,
          key: experiment.key,
          title: experiment.title,
          treatments: experiment.experimentRevisions[0].treatments.map((t: any) => ({
            id: t.id,
            key: t.key,
            title: t.title,
          })),
          treatmentId: unit.treatmentId,
          forcedAssignment: unit.forcedAssignment,
          isExposed: unit.isExposed,
        }
      })
      setData(data)
    }
  }

  useEffect(() => {
    if (showExperiments) fetchExperiments(unitId)
  }, [unitId, showExperiments])

  const selectTreatment = async ({
    experimentId,
    experimentKey,
    treatmentKey,
  }: {
    experimentId: string
    experimentKey: string
    treatmentKey: string
  }) => {
    const res = await forcedAssignment({ experimentId, value: unitId, treatmentKey })
    if (res.data.length > 0) {
      const fa = res.data.filter(
        (fa: any) => fa.experimentId === experimentId && fa.identifierValue === unitId
      )
      if (fa?.[0]?.treatmentKey && fa[0].treatmentKey === treatmentKey) {
        setData((prevData) => {
          const newData = prevData.map((exp) => {
            if (exp.id === experimentId) {
              return {
                ...exp,
                forcedAssignment: fa[0].treatmentKey,
              }
            }
            return exp
          })
          return newData
        })
        onSwitch({ experimentKey, treatmentKey })
      }
    }
  }

  const Xmark = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-x h-4 w-4"
      >
        <path d="M18 6 6 18"></path>
        <path d="m6 6 12 12"></path>
      </svg>
    )
  }

  const BexSwitcherSelector = ({ unitId, data }: { unitId: string; data: any[] }) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="bex-switcher flex flex-col relative justify-start bg-white gap-4 w-full h-full pt-14 p-6"
      >
        <button
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          }}
          onClick={() => setShowExperiments(false)}
        >
          <Xmark />
        </button>
        <div className="flex flex-col gap-8 overflow-y-scroll">
          <h3 className="text-body-sm-b font-bold">{unitId}</h3>
          <div className="flex flex-col gap-6">
            {data.length === 0 ? (
              <div>아직 할당된 실험이 없습니다.</div>
            ) : (
              data.map((exp) => {
                return (
                  <div className="flex flex-col gap-1 justify-between" key={exp.key}>
                    <div className="text-body-md-b">{exp.title}</div>
                    <div className="flex gap-2 justify-start">
                      {exp.treatments.map((t: any) => {
                        return (
                          <div
                            className={`${
                              (
                                exp.forcedAssignment
                                  ? exp.forcedAssignment === t.key
                                  : exp.isExposed
                                  ? exp.treatmentId === t.id
                                  : false
                              )
                                ? 'bg-bosalpim text-white'
                                : ''
                            } py-1 px-4 rounded-md border border-1 border-background-light cursor-pointer`}
                            key={t.id}
                            onClick={() => {
                              if (
                                exp.forcedAssignment
                                  ? exp.forcedAssignment === t.key
                                  : exp.isExposed
                                  ? exp.treatmentId === t.id
                                  : false
                              )
                                return

                              selectTreatment({
                                experimentId: exp.id,
                                experimentKey: exp.key,
                                treatmentKey: t.key,
                              })
                            }}
                          >
                            {t.key}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Draggable
        axis="y"
        defaultPosition={{ x: 0, y: -48 }}
        onDrag={() => {
          draggedRef.current = true
        }}
        onStop={() => {
          const dragged = draggedRef.current
          draggedRef.current = false
          if (!dragged) {
            setShowExperiments((prev) => !prev)
          }
        }}
        grid={[1, 3]}
      >
        <button
          className="bex-switcher-button fixed w-[52px] h-[45px] z-50 p-2 right-0 bottom-0
      rounded-lg border border-1 border-background-light text-body-lg-b
      shadow-lg text-[#2B2826] bg-white cursor-pointer"
          onMouseOver={(e) => ((e.target as HTMLDivElement).style.color = '#45C65A')}
          onMouseOut={(e) => ((e.target as HTMLDivElement).style.color = '#2B2826')}
          // onClick={() => {
          //   setShowExperiments((prev) => !prev)
          // }}
        >
          BEX
        </button>
      </Draggable>
      <Sheet
        open={showExperiments}
        setOpen={setShowExperiments}
        onClose={() => setShowExperiments(false)}
        className="h-full"
      >
        <BexSwitcherSelector unitId={unitId} data={data} />
      </Sheet>
    </>
  )
}

export const BexSwitcher = ({
  onSwitch,
}: {
  onSwitch: ({
    experimentKey,
    treatmentKey,
  }: {
    experimentKey: string
    treatmentKey: string
  }) => void
}) => {
  const env = process.env.VERCEL_ENV
  const { bexState } = useBexState()
  const { unitId } = bexState

  if (!env || env === 'production') {
    return null
  }

  if (!unitId) {
    return null
  }

  return <BexSWitcherUi unitId={unitId} onSwitch={onSwitch} />
}
