import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MdClose } from 'react-icons/md'
import classNames from 'classnames'

import Button from '@components/Button'

type ModalDataProps = {
  title?: string | React.ReactNode
  body?: string | React.ReactNode
  submitLabel?: string
  submitAction?: () => void
  closeAction?: () => void
  cancelLabel?: string
  cancelButton?: boolean
  cancelAction?: () => void
  xButton?: boolean
}
type ModalProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  timeout?: boolean
  data: ModalDataProps
  className?: string
}

const AlertModal: React.FC<ModalProps> = ({
  show,
  setShow,
  data: {
    title,
    body,
    submitLabel,
    submitAction,
    closeAction,
    cancelLabel,
    cancelButton,
    cancelAction,
    xButton,
  },
  className,
}) => {
  const CloseModal = async () => {
    closeAction && closeAction()
    setShow(false)
  }

  const handleSubmit = async () => {
    submitAction && submitAction()
    setShow(false)
  }

  const submitButtonRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={submitButtonRef}
        onClose={CloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity`} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  `relative transform overflow-hidden rounded-lg bg-white px-6 pt-10 pb-6 text-left shadow-xl transition-all`,
                  className
                )}
              >
                {xButton && (
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-black focus:outline-none"
                      onClick={CloseModal}
                    >
                      <span className="sr-only">Close</span>
                      <MdClose size={24} />
                    </button>
                  </div>
                )}

                <div className="text-center">
                  <div className="max-[640px]:w-[272px] w-[380px]">
                    <div className={'text-black pt-[1px]'}>
                      <div className={'font-bold text-2xl'}>{title}</div>
                      <div className={'font-bold text-lg'}>{body}</div>
                      <div className={'flex flex-col text-xl'}>
                        <Button.primary onClick={() => handleSubmit()} className={'!py-4'}>
                          {submitLabel ?? '확인'}
                        </Button.primary>
                        {cancelButton && (
                          <Button.secondary
                            onClick={() => (cancelAction ? cancelAction() : CloseModal())}
                            className={'!py-4 !mt-4'}
                          >
                            {cancelLabel ?? '취소'}
                          </Button.secondary>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AlertModal
