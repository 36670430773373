import React, { createContext, useContext, useState } from 'react';

interface OverlayContextValue {
  openAsync: <T>(
    render: (props: {
      isOpen: boolean;
      close: (value: T) => void;
    }) => React.ReactNode
  ) => Promise<T>;
}

const OverlayContext = createContext<OverlayContextValue | null>(null);

export function OverlayProvider({ children }: { children: React.ReactNode }) {
  const [content, setContent] = useState<React.ReactNode | null>(null);

  const openAsync = async <T,>(
    render: (props: {
      isOpen: boolean;
      close: (value: T) => void;
    }) => React.ReactNode
  ): Promise<T> => {
    return new Promise<T>((resolve) => {
      const close = (value: T) => {
        setContent(null);
        resolve(value);
      };

      setContent(render({ isOpen: true, close }));
    });
  };

  return (
    <OverlayContext.Provider value={{ openAsync }}>
      {children}
      {content}
    </OverlayContext.Provider>
  );
}

export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context)
    throw new Error('useOverlay must be used within OverlayProvider');
  return context;
};

export * from './useSelect';

// 사용예
// useOverlay

// const { openAsync } = useOverlay()

// (<Button
//   onClick={async () => {
//     const result = await openAsync(({ close }) => (
//       <ConfirmDialog
//         title="TEST"
//         cancelButton={
//           <ConfirmDialog.CancelButton onClick={() => close('cancel')}>
//             닫기
//           </ConfirmDialog.CancelButton>
//         }
//         confirmButton={
//           <ConfirmDialog.ConfirmButton onClick={() => close('confirm')}>
//             확인
//           </ConfirmDialog.ConfirmButton>
//         }
//       />
//     ))
//     console.log(result)
//   }}
// >
//   TEST
// </Button>

// 사용예
// useSelect

// const buttonRef = useRef<HTMLButtonElement>(null)
// const selectOption = useSelect()

// const handleSelect = async () => {
//   if (!buttonRef.current) return
//   const selected = await selectOption(buttonRef.current, [
//     { id: '1', name: '옵션 1' },
//     { id: '2', name: '옵션 2' },
//   ])
//   if (selected) {
//     console.log('선택된 옵션:', selected.name)
//   }
// }
// <Button ref={buttonRef} onClick={handleSelect}>
//   TEST
// </Button>)
