import { atom } from 'recoil'

const bexGroupKey = 'bexGroup'
const defaultBexGroup = {}
const bexGroupAtom = atom({
  key: `${bexGroupKey}`,
  default: defaultBexGroup,
})

export default bexGroupAtom
