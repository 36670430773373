import React, { useEffect } from 'react';

interface CustomDialogProps {
  className?: string;
  children?: React.ReactNode;
}

export function CustomDialog({ className, children }: CustomDialogProps) {
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/80" />
      <div className={`fixed inset-0 ${className}`}>{children}</div>
    </div>
  );
}
