import React from 'react';

import { useOverlay } from './index';

export function useSelect() {
  const overlay = useOverlay();

  const selectOption = async <T extends { id: string; name: string }>(
    buttonElement: HTMLElement,
    options: T[]
  ) => {
    const buttonRect = buttonElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    // 각 옵션의 높이 (padding + text height)
    const OPTION_HEIGHT = 36; // py-2(16px) + text height(20px)
    // 드롭다운 패딩
    const DROPDOWN_PADDING = 8; // py-1(8px)
    // 최대로 보여줄 옵션의 개수
    const MAX_OPTIONS = 6;

    // 실제 드롭다운 높이 계산
    const contentHeight = Math.min(
      options.length * OPTION_HEIGHT + DROPDOWN_PADDING,
      MAX_OPTIONS * OPTION_HEIGHT + DROPDOWN_PADDING
    );

    // 아래쪽 공간이 부족한지 체크
    const showAbove = buttonRect.bottom + contentHeight > windowHeight;

    const selected = await overlay.openAsync<T | null>(({ close }) => (
      <div className="fixed inset-0" onClick={() => close(null)}>
        <div
          className={`
        absolute bg-white shadow-lg rounded-lg border border-gray-200 py-1 min-w-[200px]
        animate-in fade-in slide-in-from-top-2 duration-200
      `}
          style={{
            ...(showAbove
              ? { bottom: `${window.innerHeight - buttonRect.top + 4}px` }
              : { top: `${buttonRect.bottom + 4}px` }),
            left: `${buttonRect.left}px`,
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {options.map((option) => (
            <button
              key={option.id}
              className="w-full px-4 py-2 text-left hover:bg-gray-50 text-sm"
              onClick={(e) => {
                e.stopPropagation();
                close(option);
              }}
            >
              {option.name}
            </button>
          ))}
        </div>
      </div>
    ));
    return selected;
  };
  return selectOption;
}
