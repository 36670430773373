import { UNIT_ID_COOKIE_NAME, EXPIRE_DAYS } from './constants'

const getCookie = (name: string) => {
  const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')

  return value ? value[2] : ''
}

const setCookie = (name: string, value: string, time?: number) => {
  let expires = ''
  if (time) {
    const date = new Date()
    date.setTime(date.getTime() + time * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

const deleteCookie = function (name: string) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT;'
}

const getCookieUnitId = () => {
  return getCookie(UNIT_ID_COOKIE_NAME)
}
const setCookieUnitId = (unitId: string) => {
  setCookie(UNIT_ID_COOKIE_NAME, unitId, EXPIRE_DAYS * 24 * 60 * 60)
}

const removeCookieUnitId = () => {
  setCookie(UNIT_ID_COOKIE_NAME, '', -24)
}

export { getCookie, setCookie, deleteCookie, getCookieUnitId, setCookieUnitId, removeCookieUnitId }
