// NumberPad.tsx
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MdChevronRight } from 'react-icons/md';

import { Button } from '../../Button';

export interface NumberPadProps {
  numberLabel?: string;
  maxLength?: number;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  setOpenNumberPad: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValue?: string;
  shuffle?: boolean;
  finishCallback?: () => void;
  handleFocus?: () => void;
  handleSubmit?: () => void;
  isValid?: boolean;
}

export const NumberPad2: React.FC<NumberPadProps> = ({
  numberLabel = '번호',
  maxLength = 10,
  setValue,
  setOpenNumberPad,
  defaultValue,
  finishCallback,
  handleFocus,
  handleSubmit,
  isValid,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [afterFirstTouch, setAfterFirstTouch] = useState(false);

  const finishProcess = useCallback(() => {
    if (finishCallback) finishCallback();
  }, [finishCallback]);

  const onClickSetValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleFocus?.();
    if (!afterFirstTouch) setAfterFirstTouch(true);
    const newValue = e.currentTarget.value; // Get the clicked button's value

    if (newValue === '지우기') {
      setInputValue(inputValue.slice(0, -1));
    } else if (maxLength && inputValue.length < maxLength) {
      setInputValue((prev) => prev + newValue);
    } else if (maxLength && inputValue.length === maxLength) {
      alert(`${numberLabel}은/는 ${maxLength}자를 초과 할 수 없습니다.`);
      finishProcess();
    }
  };

  useEffect(() => {
    if (setValue) setValue(inputValue);
    if (inputValue.length === maxLength && afterFirstTouch) finishProcess();
  }, [inputValue, maxLength, setValue, finishProcess, afterFirstTouch]);

  const randomNumbers = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '',
    '0',
    '지우기',
  ];

  return (
    <div
      className="fixed top-0 left-0 z-10 flex flex-col items-center justify-end w-full h-full"
      onClick={() => setOpenNumberPad(false)}
    >
      {isValid && (
        <Button
          className="rounded-none disabled:bg-bdsGray-50"
          size="large"
          full
          suffix={MdChevronRight}
          onClick={handleSubmit}
        >
          주민등록번호 입력완료
        </Button>
      )}
      <div
        className="bg-white w-full max-w-4xl rounded bg-gray"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="grid grid-cols-3 grid-rows-4 gap-1 p-1 bg-background-light font-bold">
          {randomNumbers.map((value) => (
            <button
              key={value}
              className={classNames(
                'flex items-center justify-center text-head-3 py-4 hover:bg-gray-200 rounded transition-colors duration-150 transform',
                value === '지우기' ? 'text-title-2' : ''
              )}
              onClick={onClickSetValue}
              value={value}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

NumberPad2.displayName = 'NumberPad2';
