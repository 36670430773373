import { useBexUser } from '@packages/bex'
import { useCallback } from 'react'
import { Types } from '@amplitude/analytics-browser'

import {
  AmplitudeEventLogger,
  RevenueProps,
} from '../lib/analyze/AmplitudeEventLogger'
import { MixpanelEventLogger } from '../lib/analyze/MixpanelEventLogger'

export const useEventLogging = () => {
  const amplitudeEventLogger = AmplitudeEventLogger.getInstance()
  const mixpanelEventLogger = MixpanelEventLogger.getInstance()
  const { setBexUserId } = useBexUser()

  const getPlatform = () => {
    return window.flutter_inappwebview || window.AndroidBridge ? 'app' : 'web'
  }

  const setUserId = useCallback(
    ({ userId }: { userId: string | undefined }) => {
      if (userId) {
        setBexUserId({ userId })
      }
      amplitudeEventLogger.setUserId(userId)
      mixpanelEventLogger.setUserId(userId)
    },
    [amplitudeEventLogger, mixpanelEventLogger]
  )

  const reset = useCallback(() => {
    mixpanelEventLogger.reset()
  }, [amplitudeEventLogger, mixpanelEventLogger])

  const getUserId = useCallback(() => {
    const userId = mixpanelEventLogger.getUserId()
    if (userId) {
      return userId
    }
    return amplitudeEventLogger.getUserId()
  }, [amplitudeEventLogger, mixpanelEventLogger])

  const setUserProperties = useCallback(
    ({ properties }: { properties: object | null }) => {
      amplitudeEventLogger.setUserProperties(properties)
      mixpanelEventLogger.setUserProperties(properties)
    },
    [amplitudeEventLogger, mixpanelEventLogger]
  )

  const track = useCallback(
    ({
      name,
      properties,
      options,
    }: {
      name: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      amplitudeEventLogger.track(
        name,
        {
          platform: getPlatform(),
          ...properties,
        },
        options
      )
      mixpanelEventLogger.track(
        name,
        {
          platform: getPlatform(),
          ...properties,
        },
        options
      )
    },
    [amplitudeEventLogger, mixpanelEventLogger]
  )

  const trackButtonClick = useCallback(
    ({
      name,
      properties,
      pagePath,
      options,
    }: {
      name: string
      properties?: object
      pagePath?: string
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Button Click] ${name}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [amplitudeEventLogger]
  )

  const trackAction = useCallback(
    ({
      name,
      properties,
      pagePath,
      options,
    }: {
      name: string
      properties?: object
      pagePath?: string
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Action] ${name}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [amplitudeEventLogger]
  )

  const trackPageView = useCallback(
    ({
      pagePath,
      pageName,
      properties,
      options,
    }: {
      pagePath?: string
      pageName: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Page View] ${pageName ? pageName : 'Untaged'}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [amplitudeEventLogger]
  )

  const trackShow = useCallback(
    ({
      name,
      pagePath,
      properties,
      options,
    }: {
      name: string
      pagePath?: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Show] ${name ? name : 'Untaged'}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [amplitudeEventLogger]
  )

  const trackRevenue = useCallback(
    (revenueProps: RevenueProps) => {
      amplitudeEventLogger.trackRevenue({
        ...revenueProps,
        eventProperties: {
          ...revenueProps?.eventProperties,
          platform: getPlatform(),
        },
      })
    },
    [amplitudeEventLogger]
  )

  return {
    getUserId,
    setUserId,
    setUserProperties,
    reset,
    track,
    trackButtonClick,
    trackAction,
    trackPageView,
    trackShow,
    trackRevenue,
  }
}
