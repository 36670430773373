import { BexSwitcher } from '@packages/bex'

import { useSetBexExperimentGroup } from '@hooks/useBexExperimentGroup'

export const BexSwitcherContainer = () => {
  const setBexExperimentGroup = useSetBexExperimentGroup()
  return (
    <BexSwitcher
      onSwitch={({
        experimentKey,
        treatmentKey,
      }: {
        experimentKey: string
        treatmentKey: string
      }) => {
        setBexExperimentGroup(experimentKey, treatmentKey)
      }}
    />
  )
}
