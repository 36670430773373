import React from 'react';

import { Button } from '../../Button';

interface ConfirmDialogProps {
  title: string;
  children?: React.ReactNode;
  cancelButton?: React.ReactNode;
  confirmButton?: React.ReactNode;
}

export function ConfirmDialog({
  title,
  children,
  cancelButton,
  confirmButton,
}: ConfirmDialogProps) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/50" />
      <div className="relative bg-white rounded-lg p-6 w-[90%] max-w-md">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        {children}
        <div className="mt-6 flex justify-end gap-2">
          {cancelButton}
          {confirmButton}
        </div>
      </div>
    </div>
  );
}

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
}

ConfirmDialog.CancelButton = function CancelButton({
  onClick,
  children,
}: ButtonProps) {
  return (
    <Button variant="outline" onClick={onClick}>
      {children}
    </Button>
  );
};

ConfirmDialog.ConfirmButton = function ConfirmButton({
  onClick,
  children,
}: ButtonProps) {
  return <Button onClick={onClick}>{children}</Button>;
};
